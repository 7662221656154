import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { MdOutlineSave } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Heading2 from '../../../../../../../../../../../components/text/Heading2';
import ROUTES, {
  UPDATE_STATUS_URL_PARAMS,
  URL_PARAMS,
} from '../../../../../../../../../../../core/constants/routes.constant';
import { useStyles } from './component.styles';
import { FormSelect } from '@omnigenbiodata/ui';
import { useAppSelector } from '../../../../../../../../../../../store';
import { participantStatusListSelector } from '../../../../../../../../../../../store/participant/selectors';

export interface UpdateStatusBlockProps {
  hospitalNo: string;
  pStatus?: string | null;
}

function UpdateStatusBlock({ hospitalNo, pStatus }: UpdateStatusBlockProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('portal');
  const participantStatusList = useAppSelector(participantStatusListSelector) || [];
  const [participantStatus, setParticipantStatus] = useState(pStatus || '');

  return (
    <Paper className={classes.paperDanger}>
      <Box p={4}>
        <Heading2 color="textPrimary" icon={MdOutlineSave}>
          {t('participantUpdateStatus.title')}
        </Heading2>

        <>
          <FormSelect
            placeholder=""
            name="participantStatuses"
            label={'Status'}
            onChange={(e: any) => {
              setParticipantStatus(e.currentTarget.value);
            }}
            onBlur={() => {}}
            value={participantStatus}
            options={participantStatusList
              .filter((item) => item !== 'WITHDRAWN')
              .map((status) => ({
                value: status,
                label: t(`participantUpdateStatus.statusList.${status}`),
              }))}
          />
          <Button
            variant="contained"
            color="success"
            size="large"
            data-testid="withdraw"
            component={Link}
            to={
              participantStatus === 'WITHDRAWN'
                ? ROUTES.participantWithdrawal.replace(URL_PARAMS.hospitalNo, hospitalNo)
                : ROUTES.updateParticipantStatus
                    .replace(URL_PARAMS.hospitalNo, hospitalNo)
                    .replace(UPDATE_STATUS_URL_PARAMS.participantStatus, participantStatus)
            }
          >
            {t('participantUpdateStatus.button')}
          </Button>
        </>
      </Box>
    </Paper>
  );
}

export default UpdateStatusBlock;
