import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import GroupIcon from '@mui/icons-material/Group';
import { ButtonRow, FormInput, FormSelect, Loader } from '@omnigenbiodata/ui';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import ROUTES, { URL_PARAMS } from '../../../../../../core/constants/routes.constant';
import InnerLayout from '../../../../../../layout/Inner';
import Heading1 from '../../../../../../components/text/Heading1';
import Heading2 from '../../../../../../components/text/Heading2';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import {
  getParticipantByHospitalNoThunk,
  resetParticipantResult,
  isBusySelector,
  hasLookupErrorSelector,
  participantProfileSelector,
  lookupErrorTypeSelector,
  formValueSelector,
  getParticipantStatusesThunk,
  participantStatusListSelector,
} from '../../../../../../store/participant';
import { LookupErrorTypes } from '../../../../../../core/types/common.types';

function ParticipantsLookupScene() {
  const apiRef = useGridApiRef();
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const profile = useAppSelector(participantProfileSelector);
  const hasLookupError = useAppSelector(hasLookupErrorSelector);
  const lookupErrorType = useAppSelector(lookupErrorTypeSelector);
  const lookupValues = useAppSelector(formValueSelector);
  const [rowSelectionModel] = useState([]);
  const [participantStatus, setParticipantStatus] = useState('');
  const participantStatusList = useAppSelector(participantStatusListSelector) || [];

  useEffect(() => {
    dispatch(resetParticipantResult());
    dispatch(getParticipantStatusesThunk({}));
  }, [dispatch]);

  const validationSchema = yup.object({
    hospitalNo: yup.lazy((value: string) =>
      value && value.charAt(0).toUpperCase() === 'D'
        ? yup
            .string()
            .required(
              t('hospitalNumRequired', {
                ns: 'validation',
              }),
            )
            .matches(/^DMSA[0-9]{8}-\w{2}$/, t('participantIdFormatError', { ns: 'validation' }))
        : yup
            .string()
            .required(
              t('hospitalNumRequired', {
                ns: 'validation',
              }),
            )
            .matches(
              /^[0-9]+$/,
              t('hospitalNoFormatError', {
                ns: 'validation',
              }),
            )
            .min(
              8,
              t('hospitalNoLengthError', {
                ns: 'validation',
              }),
            )
            .max(
              8,
              t('hospitalNoLengthError', {
                ns: 'validation',
              }),
            ),
    ),

    participantStatus: yup.string(),
  });

  const columns = [
    { field: 'id', headerName: 'Participant ID', flex: 2 },
    { field: 'firstName', headerName: 'First Name', flex: 2 },
    { field: 'lastName', headerName: 'Last Name', flex: 2 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
  ];

  const formik = useFormik({
    initialValues: {
      hospitalNo: '',
      participantStatus: '',
    },
    validationSchema,
    onSubmit: ({ hospitalNo }) => {
      dispatch(getParticipantByHospitalNoThunk({ hospitalNo }));
    },
  });

  if (profile && formik.values.hospitalNo) {
    return <Navigate to={ROUTES.participant.replace(URL_PARAMS.hospitalNo, profile.hospitalNo)} />;
  }

  return (
    <>
      <InnerLayout title="Site" title2="Portal">
        <Heading1 icon={GroupIcon}>{t('participantLookup.title')}</Heading1>

        <Paper elevation={9}>
          <Box p={6}>
            <Heading2 align="center">{t('participantLookup.subtitle')}</Heading2>
            <Typography paragraph variant="body1" align="center">
              {t('participantLookup.para1')}
            </Typography>
            <Box mb={4}>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={6} md={6}>
                  <Paper elevation={2}>
                    <form onSubmit={formik.handleSubmit}>
                      <Box p={4}>
                        {hasLookupError && lookupErrorType.includes(LookupErrorTypes.NOT_FOUND) && (
                          <Alert color="error">
                            {t('participantLookup.errors.RecordNotFoundError', {
                              hospitalNo: lookupValues?.hospitalNo,
                            })}
                          </Alert>
                        )}
                        {hasLookupError && !lookupErrorType.includes(LookupErrorTypes.NOT_FOUND) && (
                          <Alert color="error">{t('participantLookup.errors.UnexpectedError')}</Alert>
                        )}
                        <FormInput
                          error={formik.errors.hospitalNo}
                          name="hospitalNo"
                          label={t('participantLookup.label')}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          touched={formik.touched.hospitalNo}
                          value={formik.values.hospitalNo}
                        />
                        <ButtonRow
                          showBack={false}
                          forwardColor="primary"
                          justifyContent="center"
                          forwardLabel={t('participantLookup.button')}
                          buttonSize="large"
                          forwardIcon={<SearchIcon style={{ fontSize: 30 }} />}
                        />
                      </Box>
                    </form>
                  </Paper>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Paper elevation={2}>
                    <Box p={4}>
                      <FormSelect
                        placeholder=""
                        name="participantStatuses"
                        label={'Status'}
                        onChange={(e: any) => {
                          setParticipantStatus(e.currentTarget.value);
                        }}
                        onBlur={() => {}}
                        value={participantStatus}
                        options={participantStatusList.map((status) => ({
                          value: status,
                          label: t(`participantUpdateStatus.statusList.${status}`),
                        }))}
                      />
                      <ButtonRow
                        showBack={false}
                        forwardColor="primary"
                        justifyContent="center"
                        forwardLabel={t('participantLookup.button')}
                        buttonSize="large"
                        forwardIcon={<SearchIcon style={{ fontSize: 30 }} />}
                      />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ mt: 4, display: 'none' }}>
              <Paper elevation={2}>
                <Box p={4}>
                  <Heading2>Participant Results</Heading2>
                  <DataGrid
                    apiRef={apiRef}
                    // loading={loading}
                    rows={[]}
                    autoHeight
                    pageSizeOptions={[10, 25, 50]}
                    checkboxSelection
                    columns={columns}
                    onRowSelectionModelChange={() => {}}
                    rowSelectionModel={rowSelectionModel}
                    // initialState={{
                    //   ...[data.initialState],
                    //   pagination: { paginationModel: { pageSize: 50 } },
                    // }}
                  />
                </Box>
              </Paper>
            </Box>
          </Box>
        </Paper>
      </InnerLayout>
      <Loader label={t('participantLookup.busy')} isVisible={isBusy} />
    </>
  );
}

export default ParticipantsLookupScene;
